import React from "react"
import ContextConsumer from "./Context"
import { Link } from "gatsby"

const MyName = (props) => {
    const { layout } = props.pageContext
    
    return (
        <ContextConsumer>
            {({ reset }) => (
                <header id="myname">
                    <Link to="/" onClick={reset}>
                        <h1>Bobby Lam</h1>
                        {layout === "color" ? <h2>Colorist</h2> : <h2>Director of Photography</h2>}
                    </Link>
                </header>
            )}
        </ContextConsumer>
    )
}

export default MyName