import * as React from 'react'
import ContextConsumer from "./Context"
import ImageGallery from './imageGallery'
import VideoPlayer from './videoPlayer'
import { useCarouselImages } from '../hooks/allCarouselImages'

const PictureBox = () => {
    let images = useCarouselImages()

    return (
        <ContextConsumer>
            {({ data }) => {
                let paddingStyleCorrection = { paddingBottom: (100 / data.viewerAspectRatio) + "%"}

                let imageGallery = <ImageGallery images={images} shuffle={data.shuffleAllImages} style={paddingStyleCorrection} />
                let videoPlayer = <VideoPlayer style={paddingStyleCorrection} videoLoaded={data.videoLoaded} />

                let innerBox = data.videoLoaded ? videoPlayer : imageGallery

                return (
                    <div className="picturebox">
                        {innerBox}
                    </div>
                )
            }}
        </ContextConsumer>
    )
}



export default PictureBox