import { useStaticQuery, graphql } from "gatsby"

export const useCarouselImages = () => {
    const { allFile } = useStaticQuery(graphql`
      query Carousel {
        allFile(filter: {sourceInstanceName: {eq: "carousel"}}, sort: {name: ASC}) {
          nodes {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
            name
            id
          }
        }
      }
    `)

    return allFile.nodes
}