import * as React from 'react'
import { MainContextProviderComponent } from '../components/Context'

import NavMenu from '../components/navMenu'
import PictureBox from '../components/pictureBox'
import VideoInfo from '../components/videoInfo'
import MyName from '../components/myName'

const MainLayout = ({ children, pageContext }) => (
    <main>
        <MainContextProviderComponent pageContext={pageContext}>
            
            <MyName pageContext={pageContext} />
            
            <PictureBox />
            <VideoInfo pageContext={pageContext} />
            {pageContext.layout !== "color" && <NavMenu />}
            
            {children}

        </MainContextProviderComponent>
    </main>
)


export default MainLayout