import * as React from 'react'
import { Link } from "gatsby"

const SelectedWorksLink = props => (
    <Link
        {...props}
        getProps={({ location }) => {
            if (location.pathname.includes("features-tv") ||
                location.pathname.includes("music-videos") ||
                location.pathname.includes("commercials") ||
                location.pathname.includes("about")) {
                return
            }

            return {
                style: {
                    'borderBottom': "1px solid"
                }
            }
        }}
    />
)

const NavMenu = () => {
    return (
        <nav className="menu">
            <SelectedWorksLink to="/" activeClassName="active">Selected Works</SelectedWorksLink>
            <Link to="/features-tv/" activeClassName="active" partiallyActive={true}>Features / TV</Link>
            <Link to="/commercials/" activeClassName="active" partiallyActive={true}>Commercials</Link>
            <Link to="/music-videos/" activeClassName="active" partiallyActive={true}>Music Videos</Link>
            <Link to="/about/" activeClassName="active">About</Link>
        </nav>
    )
}

export default NavMenu