import React from "react"
import { MainContext } from "../components/Context"
import he from 'he'

class VideoInfo extends React.Component {

    render() {
        const { layout } = this.props.pageContext
        let videoLoaded = this.context.data.videoLoaded

        if (!videoLoaded) return null
        
        let videoInfo

        let directorLabel = <p className="label">Director</p>
        let videoDirector

        let prodCoLabel = <p className="label">Production</p>
        let videoProdCo

        let clientLabel = <p className="label">Client</p>
        let videoClient

        let artistLabel = <p className="label">Artist</p>
        let videoArtist

        let dpLabel = <p className="label">DP</p>
        let videoDP

        let videoAward

        if (videoLoaded.info.director) videoDirector = <p className="belongs-to">{he.decode(videoLoaded.info.director)}</p>
        if (videoLoaded.info.production_company) videoProdCo = <p className="belongs-to">{he.decode(videoLoaded.info.production_company)}</p>
        if (videoLoaded.info.client) videoClient = <p className="belongs-to">{he.decode(videoLoaded.info.client)}</p>
        if (videoLoaded.info.artist) videoArtist = <p className="belongs-to">{he.decode(videoLoaded.info.artist)}</p>
        if (layout === "color" && videoLoaded.info.dp) videoDP = <p className="belongs-to">{he.decode(videoLoaded.info.dp)}</p>
        if (videoLoaded.info.award) videoAward = <p className="award">{he.decode(videoLoaded.info.award)}</p>

        switch (videoLoaded.category) {
            case "narrative":
                videoInfo = (
                    <div className="video-info">
                        <h1>{he.decode(videoLoaded.title)}</h1>
                        <div className="credits">
                            {videoDirector ? directorLabel : null}{videoDirector}
                            {videoDP ? dpLabel : null}{videoDP}
                            {videoProdCo ? prodCoLabel : null}{videoProdCo}
                        </div>
                        {videoAward}
                    </div>
                )
            break;

            case "commercial":
                videoInfo = (
                    <div className="video-info">
                        <h1>{he.decode(videoLoaded.title)}</h1>
                        <div className="credits">
                            {videoClient ? clientLabel : null}{videoClient}
                            {videoDirector ? directorLabel : null}{videoDirector}
                            {videoDP ? dpLabel : null}{videoDP}
                            {videoProdCo ? prodCoLabel : null}{videoProdCo}
                        </div>
                        {videoAward}
                    </div>
                )
            break;

            case "mv":
                videoInfo = (
                    <div className="video-info">
                        <h1>{he.decode(videoLoaded.title)}</h1>
                        <div className="credits">
                            {videoArtist ? artistLabel : null}{videoArtist}
                            {videoDirector ? directorLabel : null}{videoDirector}
                            {videoDP ? dpLabel : null}{videoDP}
                            {videoProdCo ? prodCoLabel : null}{videoProdCo}
                        </div>
                        {videoAward}
                    </div>
                )
            break;

            default:
                videoInfo = (
                    <div className="video-info">
                        <h1>{he.decode(videoLoaded.title)}</h1>
                        <div className="credits">
                            {videoArtist ? artistLabel : null}{videoArtist}
                            {videoClient ? clientLabel : null}{videoClient}
                            {videoDirector ? directorLabel : null}{videoDirector}
                            {videoDP ? dpLabel : null}{videoDP}
                            {videoProdCo ? prodCoLabel : null}{videoProdCo}
                        </div>
                        {videoAward}
                    </div>
                )
                break;
        }

        return videoInfo
    }
}
VideoInfo.contextType = MainContext

export default VideoInfo