import React from "react"

const defaultData = {
    videoLoaded: null,
    viewerAspectRatio: 2.4,
    shuffleAllImages: false
}

const defaultContextValue = {
  data: defaultData,
  set: () => {},
  reset: () => {}
}

const MainContext = React.createContext(defaultContextValue)
const { Provider, Consumer } = MainContext

class MainContextProviderComponent extends React.Component {
  constructor() {
    super()

    this.setData = this.setData.bind(this)
    this.resetData = this.resetData.bind(this)

    this.state = {
      ...defaultContextValue,
      set: this.setData,
      reset: this.resetData,
    }
  }

  componentDidUpdate(){
    if (!this.state.data.videoLoaded) {
      return
    }

    const { layout } = this.props.pageContext
    const { category } = this.state.data.videoLoaded
    
    if (layout !== "color" && category === "colorOnly") {
      this.resetData()
    }
  }

  resetData() {
    this.setData(defaultData)
    this.setData({ shuffleAllImages: true })
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { Consumer as default, MainContext, MainContextProviderComponent }