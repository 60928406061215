import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

class ImageGallery extends React.Component {

    constructor(props) {
        super(props)
        
        this.images = props.images
        this.state = { imageShown: 0 }
        this.shuffleLimit = 1

        if (this.props.shuffle) { this.shuffleEverything() }
        else { this.shuffleAfterLimit() }

        this.iterateImage = this.iterateImage.bind(this)
    }

    componentDidMount() {
        this.interval = setInterval(this.iterateImage, 13000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    shuffleAfterLimit() {
        let unmodifiedStart = this.images.slice(0, this.shuffleLimit)
        let shuffledEnd = this.images.slice(this.shuffleLimit)
        shuffle(shuffledEnd)

        this.images = unmodifiedStart.concat(shuffledEnd)
    }

    shuffleEverything() {
        shuffle(this.images)
    }

    iterateImage() {
        var i = this.state.imageShown

        if (i < this.images.length - 1){
            i++
        } else {
            i = 0
        }

        this.setState({imageShown: i})
    }

    createAltText(string) {
        let openingPattern = /^\d*_/
        var altText = string.replace(openingPattern, "")

        return altText
    }

    render() {
        const fadetime = 1500
        var i = this.state.imageShown

        var images = this.images.map((node) => (
            <CSSTransition key={node.id} timeout={fadetime} classNames="fade">
                <GatsbyImage image={getImage(node)} alt={this.createAltText(node.name)} />
            </CSSTransition>
        ))

        return (
            <TransitionGroup component="div" className="content" style={this.props.style}>
                {images[i]}
            </TransitionGroup>
        )
    }
}

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

export default ImageGallery