import React from 'react'
import ReactPlayer from 'react-player/vimeo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { primaryInput } from 'detect-it'

class VideoPlayer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {showingPlaceholder: true}

        this.handleStart = this.handleStart.bind(this)
        this.handleStop = this.handleStop.bind(this)
    }

    componentDidUpdate(prevProps){
        if (this.props.videoLoaded.id !== prevProps.videoLoaded.id) {
            this.setState({ showingPlaceholder: true })
        }
    }

    handleStart() {
        this.setState({ showingPlaceholder: false })
    }

    handleStop() {
        this.setState({ showingPlaceholder: true })
    }

    render() {
        const video = this.props.videoLoaded

        let inTheBox

        const videoLink = "https://vimeo.com/" + video.vimeo_id + "/"

        let playButtonColor = { color: "#ffffff" }
        if (video.color_of_play_button){
            playButtonColor = { color: video.color_of_play_button }
        }

        let playButton = <div className="playbutton" style={playButtonColor}>►</div>

        if (primaryInput === "mouse" && this.state.showingPlaceholder){
            inTheBox = (
                <div className="placeholder-image content" onClick={this.handleStart} onKeyUp={this.handleStart} role="button" tabIndex={0} style={this.props.style}>
                    <GatsbyImage image={getImage(video.placeholder)} alt={video.title} />
                    {playButton}
                </div>
            )
        } else {
            inTheBox = <ReactPlayer className="react-player content" url={videoLink} playing={true} controls={true} width="auto" height="auto" volume={1} onEnded={this.handleStop} style={this.props.style} />
        }

        return inTheBox
    }

}

export default VideoPlayer